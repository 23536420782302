<template>
  <v-container>
    <v-data-table
      elevation="10"
      dense
      :headers="headers"
      :items="indexedItems"
      item-key="index"
      :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Beni per pagina',
            itemsPerPageAllText: 'Tutti i beni',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"

    >
      <template v-slot:no-data>
        <v-sheet> Nessun bene </v-sheet>
      </template>
      <template v-slot:no-results>
        <v-sheet> Nessun bene </v-sheet>
      </template>
      <template v-slot:item.ID="{ item }">
        <span>{{ item.ID }} </span>
      </template>
      <template v-slot:item.DESC="{ item }">
        <span v-if="item.MARCA">{{ item.MARCA }} </span>
        <span>{{ item.DS_BENE }} </span>
      </template>
      <template v-slot:item.DA="{ item }">
        <span>{{ formatDate(item.RoomAsset.STARTDATE) }}</span>
      </template>
      <template v-slot:item.A="{ item }">
        <span>{{ formatDate(item.RoomAsset.ENDDATE) }}</span>
      </template>
      <template v-slot:item.icons="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                $store.state.userRole === 'admin' ||
                $store.state.userRole === 'operator' ||
                $store.state.userRole === 'auditor'
              "
              icon
              :to="'/assets/' + item.ID"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>visualizza</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");

export default {
  name: "AssetsRoomHistory",
  data: () => {
    return {
      search: "",
      selected: [],
    };
  },
  props: {
    assets: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("D MMM YYYY");
    },
  },
  computed: {
    indexedItems() {
      return this.assets.map((item, index) => ({
        index: index,
        ...item,
      }));
    },
    headers() {
      return [
        {
          text: "Numero Inv.",
          align: "start",
          sortable: true,
          value: "ID",
          width: '10%',
        },
        {
          text: "Descrizione",
          align: "start",
          sortable: true,
          value: "DESC",
          width: '40%',
        },
        {
          text: "In questa stanza da",
          align: "start",
          sortable: true,
          value: "DA",
        },
        {
          text: "A",
          align: "start",
          sortable: true,
          value: "A",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "icons",
        },
      ];
    },
  },
};
</script>