<template>
  <v-container >
    <v-snackbar centered
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
      :multi-line="true"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="confirmRemoveDialog" persistent max-width="300">
      <v-card elevation="8">
        <v-card-title>Conferma</v-card-title>
        <v-card-text>
          Confermi la rimozione del responsabile &laquo;{{
            selectedName
          }}&raquo;? <br /><br /><br />
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                confirmRemoveDialog = false;
                removeUser();
              "
              >OK</v-btn
            >
            <v-btn @click="confirmRemoveDialog = false">Annulla</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" persistent max-width="500">
      <v-card elevation="8">
        <v-card-title >Aggiungi responsabile</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="thisUser"
            :loading="usersLoading"
            :items="allusers"
            :search-input.sync="searchuser"
            item-text="text"
            item-value="MATRICOLA"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            return-object
            label="Ricerca persona"
          ></v-autocomplete>
          <br /><br /><br />
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                addDialog = false;
                addUser();
              "
              >OK</v-btn
            >
            <v-btn @click="addDialog = false">Annulla</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn v-if="source === 'local' && ($store.state.userRole === 'admin' ||
                $store.state.userRole === 'operator')" @click="addDialog = true">Aggiungi responsabile</v-btn>
    <v-data-table
      elevation="10"
      dense
      :headers="headers"
      :items="users"
      item-key="ID"
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-sheet> Nessun utente </v-sheet>
      </template>
      <template v-slot:no-results>
        <v-sheet> Nessun utente </v-sheet>
      </template>
      <template v-slot:item.COGNOME="{ item }">
        <span v-if="item.LOCAL_COGNOME">{{ item.LOCAL_COGNOME }} </span>
        <span v-else>{{ item.COGNOME }} </span>
        <span v-if="item.LOCAL_NOME">{{ item.LOCAL_NOME }}</span>
        <span v-else>{{ item.NOME }}</span>
      </template>
      <template v-slot:item.DA="{ item }">
        <span>{{ formatDate(item.UserRoom.STARTDATE) }}</span>
      </template>
      <template v-slot:item.icons="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                $store.state.userRole === 'admin' ||
                $store.state.userRole === 'operator' ||
                $store.state.userRole === 'auditor'
              "
              icon
              :to="'/users/' + item.MATRICOLA"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>Visualizza</span>
        </v-tooltip>
        <v-tooltip v-if="source === 'local'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                $store.state.userRole === 'admin' ||
                $store.state.userRole === 'operator'
              "
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                selectedUser = item.MATRICOLA;
                selectedName = item.COGNOME + ' ' + item.NOME;
                confirmRemoveDialog = true;
              "
            >
              <v-icon small>mdi-exit-run</v-icon>
            </v-btn>
          </template>
          <span>Rimuovi responsabile</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");

export default {
  name: "UsersInRoom",
  data: () => {
    return {
      search: "",
      selectedUser: null,
      selectedName: "",
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: -1,
      confirmRemoveDialog: false,
      addDialog: false,
      usersLoading: false,
      thisUser: null,
      allusers: [],
      searchuser: null,
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    room: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  watch: {
    searchuser(val) {
      val && val !== this.thisUser && this.queryUsers(val);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("D MMM YYYY");
    },
    queryUsers(v) {
      this.usersLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/users/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let user of response.data) {
            let name = user.MATRICOLA + " " + user.COGNOME + " " + user.NOME;
            user.text = name;
          }
          this.allusers = response.data;
          this.usersLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
    },
    removeUser() {
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/rooms/" +
            this.room +
            "/removeuser/" +
            this.selectedUser,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Responsabile rimosso correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.$emit("reload-room");
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          this.errorText = err;
        });
    },
    addUser() {
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/rooms/" +
            this.room +
            "/adduser/" +
            this.thisUser.MATRICOLA,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Responsabile aggiunto correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.$emit("reload-room");
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          this.errorText = err;
        });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "COGNOME",
        },
        {
          text: "Da",
          align: "start",
          sortable: false,
          value: "DA",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "icons",
        },
      ];
    },
  },
};
</script>