<template>
  <v-container>
        <v-overlay :value="loading">
      <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-row v-if="roomFound">
      <v-col cols="12">
        <v-card>
          <v-card-title class="justify-center">
            <v-row>
              <v-col cols="2"></v-col
              ><v-col cols="8">{{ room.ID }}</v-col></v-row
            ></v-card-title
          >
          <v-card-text>
            <v-row v-for="(value, field) in room" :key="field">
               
              <v-row v-if="toShow(field, value) && labelMappingRoom[field].visible" align="end">
                <v-spacer></v-spacer>
                <v-col cols="2" class="text-right" align-self="center">{{ labelMappingRoom[field].label != "" ? labelMappingRoom[field].label : field  }}:</v-col>
                <v-col cols="8">
                  <v-text-field
                    v-if="
                      localData(field) &&
                        (userRole === 'admin' || userRole === 'operator')  && !labelMappingRoom[field].isflag
                    "
                    v-model="room[field]"
                    :value="value"
                    placeholder="valore"
                    hide-details="auto"
                  ></v-text-field>
                  <v-switch v-else-if="localData(field) && (userRole === 'admin' || userRole === 'operator')" v-model="room[field]" :label="room[field] ? 'SI' : 'NO'" height="12"></v-switch>
                  <div v-else>
                    {{ value }}
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <br />
            <v-spacer></v-spacer>
            <v-btn
              v-if="userRole === 'admin' || userRole === 'operator'"
              @click="saveRoom"
            >
              Salva
            </v-btn>
            <v-btn @click="$router.go(-1)"> Annulla </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-text>
            <br />
            <br />
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Persone<span
                          ><v-chip class="ma-2 ">{{
                            filterUsers(room.Users, true).length
                          }}</v-chip></span
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <users-in-room
                          :users="filterUsers(room.Users, true)"
                          :room="room.ID"
                          :source="room.SOURCE"
                          @reload-room="fullReloadRoom"
                          @start-loading="loading = true"
                        ></users-in-room>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              >Storico</v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <users-history
                                :users="filterUsers(room.Users, false)"
                              ></users-history>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels
                    :disabled="loadingAssets"
                    v-model="assetStatus"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Beni
                        <span
                          ><v-chip v-if="!loadingAssets" class="ma-2 ">{{
                            filterAssets(assets, true).length
                          }}</v-chip>
                          <v-chip v-else class="ma-2 "
                            ><v-progress-circular
                              indeterminate
                              size="20"
                              width="2"
                              color="primary" 
                            ></v-progress-circular
                          ></v-chip>
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <assets-in-room
                          :assets="filterAssets(assets, true)"
                          :loadingAssets="loadingAssets"
                          @reload-room="fullReloadRoom"
                          @start-loading="loading = true"
                        ></assets-in-room>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              >Storico</v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <assets-history
                                :assets="filterAssets(assets, false)"
                              ></assets-history>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import UsersHistory from "../components/UsersHistory.vue";
import UsersInRoom from "../components/UsersInRoom.vue";
import AssetsInRoom from "../components/AssetsInRoom.vue";
import AssetsHistory from "../components/AssetsRoomHistory.vue";
import { mapState } from "vuex";

export default {
  name: "Room",
  components: { UsersHistory, UsersInRoom, AssetsInRoom, AssetsHistory },
  data: () => ({
    room: {},
    permSnackbar: false,
    snackbarColor: "success",
    errorText: "",
    roomFound: false,
    timeout: -1,
    assetStatus: null,
    loadingAssets: false,
    assetInRoomValue: null,
    assets: [],
    firsttime: true,
    loading: false,
  }),
  created: function() {
    this.loadRoom();
  },
  computed: {
    ...mapState(["userInitials", "userRole", "labelMappingRoom"]),
  },
  methods: {
    fullReloadRoom() {
      this.loadRoom();
      this.loadAssets();
    },
    loadAssets() {
      if (this.assetStatus === null && !this.firsttime) {
        return;
      }
      this.firsttime = false;
      this.loadingAssets = true;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/rooms/" +
            this.$route.params.id +
            "/assets",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.loadingAssets = false;
          this.assets = response.data;
          this.loadingAssets = false;
        })
        .catch((err) => {
          console.log(err.response.data.error);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          this.loading = false;
          this.userFound = false;
          this.loadingAssets = false;
        });
    },
    loadRoom() {
      this.loading = true;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/rooms/" +
            this.$route.params.id,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.room = response.data;
          this.loading = false;
          this.roomFound = true;
        })
        .catch((err) => {
          //console.log(err.response.data.error)
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          this.loading = false;
        });
    },
    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, H:mm");
    },
    saveRoom() {
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/rooms/" +
            this.$route.params.id,
          this.room,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Stanza salvata correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          this.errorText = err;

          this.loading = false;
        });
    },
    localData(field) {
      return field.startsWith("LOCAL_");
    },
    toShow(field, value) {
      return (
        !Array.isArray(value) &&
        field !== "createdAt" &&
        field !== "updatedAt" &&
        (value !== null || this.localData(field))
      );
    },
    filterAssets(assets, current) {
      let filtered = [];
      for (let asset of assets) {
        if (asset.DT_BUONO_SCARICO) {
          continue;
        }
        if (asset.RoomAsset.ENDDATE === null) {
          if (current) {
            filtered.push(JSON.parse(JSON.stringify(asset)));
          }
        } else {
          if (!current) {
            filtered.push(JSON.parse(JSON.stringify(asset)));
          }
        }
      }
      return filtered;
    },
    filterUsers(users, current) {
      let filtered = [];
      for (let user of users) {
        if (user.UserRoom.ENDDATE === null) {
          if (current) {
            filtered.push(JSON.parse(JSON.stringify(user)));
          }
        } else {
          if (!current) {
            filtered.push(JSON.parse(JSON.stringify(user)));
          }
        }
      }
      return filtered;
    },
  },
  beforeMount() {
    this.loadAssets();
  },
};
</script>
