var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-snackbar',{attrs:{"centered":"","color":_vm.snackbarColor,"timeout":_vm.timeout,"multi-line":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){_vm.permSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.permSnackbar),callback:function ($$v) {_vm.permSnackbar=$$v},expression:"permSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.confirmRemoveDialog),callback:function ($$v) {_vm.confirmRemoveDialog=$$v},expression:"confirmRemoveDialog"}},[_c('v-card',{attrs:{"elevation":"8"}},[_c('v-card-title',[_vm._v("Conferma")]),_c('v-card-text',[_vm._v(" Confermi la rimozione del responsabile «"+_vm._s(_vm.selectedName)+"»? "),_c('br'),_c('br'),_c('br'),_c('v-row',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.confirmRemoveDialog = false;
              _vm.removeUser();}}},[_vm._v("OK")]),_c('v-btn',{on:{"click":function($event){_vm.confirmRemoveDialog = false}}},[_vm._v("Annulla")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',{attrs:{"elevation":"8"}},[_c('v-card-title',[_vm._v("Aggiungi responsabile")]),_c('v-card-text',[_c('v-autocomplete',{staticClass:"mx-4",attrs:{"loading":_vm.usersLoading,"items":_vm.allusers,"search-input":_vm.searchuser,"item-text":"text","item-value":"MATRICOLA","cache-items":"","flat":"","hide-no-data":"","hide-details":"","return-object":"","label":"Ricerca persona"},on:{"update:searchInput":function($event){_vm.searchuser=$event},"update:search-input":function($event){_vm.searchuser=$event}},model:{value:(_vm.thisUser),callback:function ($$v) {_vm.thisUser=$$v},expression:"thisUser"}}),_c('br'),_c('br'),_c('br'),_c('v-row',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.addDialog = false;
              _vm.addUser();}}},[_vm._v("OK")]),_c('v-btn',{on:{"click":function($event){_vm.addDialog = false}}},[_vm._v("Annulla")])],1)],1)],1)],1),(_vm.source === 'local' && (_vm.$store.state.userRole === 'admin' ||
              _vm.$store.state.userRole === 'operator'))?_c('v-btn',{on:{"click":function($event){_vm.addDialog = true}}},[_vm._v("Aggiungi responsabile")]):_vm._e(),_c('v-data-table',{attrs:{"elevation":"10","dense":"","headers":_vm.headers,"items":_vm.users,"item-key":"ID","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun utente ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun utente ")])]},proxy:true},{key:"item.COGNOME",fn:function(ref){
              var item = ref.item;
return [(item.LOCAL_COGNOME)?_c('span',[_vm._v(_vm._s(item.LOCAL_COGNOME)+" ")]):_c('span',[_vm._v(_vm._s(item.COGNOME)+" ")]),(item.LOCAL_NOME)?_c('span',[_vm._v(_vm._s(item.LOCAL_NOME))]):_c('span',[_vm._v(_vm._s(item.NOME))])]}},{key:"item.DA",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.UserRoom.STARTDATE)))])]}},{key:"item.icons",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(
              _vm.$store.state.userRole === 'admin' ||
              _vm.$store.state.userRole === 'operator' ||
              _vm.$store.state.userRole === 'auditor'
            )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":'/users/' + item.MATRICOLA}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Visualizza")])]),(_vm.source === 'local')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(
              _vm.$store.state.userRole === 'admin' ||
              _vm.$store.state.userRole === 'operator'
            )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.selectedUser = item.MATRICOLA;
              _vm.selectedName = item.COGNOME + ' ' + item.NOME;
              _vm.confirmRemoveDialog = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-exit-run")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Rimuovi responsabile")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }